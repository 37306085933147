.topnav {
  overflow: hidden;
  background-color: $white;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  &__links {
    //float: right;
    //margin-left: 400px;

    @media screen and (min-width: 600px) {
      //margin: 0;
      float: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-top: 1.25rem;
    }
  }
  &__menu a {
    float: left;
    display: block;
    color: $darkBlue;
    text-align: center;
    padding: 14px 31px;
    text-decoration: none;
    font-size: 17px;
  }
  &__logo {
    margin-top: 0;
  }
}

.topnav a:hover {
  background-color: $lightGrayishBlue;
  color: $darkBlue;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
