.contact {
  background-color: $lightGrayishBlue;
  color: $darkBlue;
  padding: 2.5rem;
  text-align: center;
  padding-top: 6.25rem;

  .container {
    @include breakpoint-up(large) {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        "info1 info2"
        "info1 info2";
      gap: 1rem;
      justify-items: start;
      color: $darkBlue;
    }
  }

  @include breakpoint-up(medium) {
    text-align: left;
  }
  &__info {
    //display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9375rem;

    @include breakpoint-up(large) {
      justify-content: space-between;
      align-items: start;
    }

    &.col1 {
      @include breakpoint-up(large) {
        grid-area: info1;
      }
    }

    &.col2 {
      @include breakpoint-down(medium) {
        margin-bottom: 1.875rem;
      }

      @include breakpoint-up(large) {
        grid-area: info2;
      }
    }

    a {
      line-height: 2.25;
      transition: color 150ms ease-in-out;

      &:hover {
        color: $limeGreen;
      }
    }
  }
  &__form {
    label {
      display: block;
      margin-bottom: 0;
    }

    input,
    textarea {
      display: block;
      width: 100%;
      padding: 0 20px;
      border: 2px solid $lightGrayishBlue;
      border-radius: 5px;
      transition: all 0.3s ease;
      height: 50px;
      line-height: 46px;
      margin-bottom: 20px;
      outline: none;
      color: $darkBlue;
      font-size: 15px;
      letter-spacing: 0.1px;
      &:focus {
        border-color: $grayishBlue;
      }
    }
    textarea {
      height: 120px;
    }
  }
  &__intro {
    margin-bottom: 3.75rem;

    @include breakpoint-up(large) {
      width: 90%;

      h2 {
        text-align: center;
        margin-left: 0;
        padding-left: 15.75rem;
      }
    }
  }

  &__grid {
    @include breakpoint-up(medium) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: 0.9375rem;

    @include breakpoint-up(medium) {
      flex: 0 0 50%;
    }

    @include breakpoint-up(large) {
      flex: 1;
    }
  }
  &__image {
    margin-bottom: 1.875rem;
    width: 90%;
    text-align: center;
    @include breakpoint-up(large) {
      margin-bottom: 2.75rem;
    }
  }

  &__icon {
    margin-bottom: 1.875rem;

    @include breakpoint-up(large) {
      margin-bottom: 2.75rem;
    }
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.15;
    color: $darkBlue;
    margin-bottom: 1rem;

    @include breakpoint-up(large) {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__description {
    font-size: 0.875rem;
    line-height: 1.5;
  }
}

/*==========================
  Contact
  Address
  ============================*/
.rn-address {
  margin-top: 30px;
  border: 1px solid #ebebeb;
  padding: 40px;
  border-radius: 10px;
  padding-top: 54px;
  background: #fff;
  padding-bottom: 50px;
  .icon {
    border: 1px solid #ebebeb;
    width: 78px;
    height: 78px;
    color: $lightGrayishBlue;
    line-height: 73px;
    text-align: center;
    border-radius: 100%;
    background: $white;
    font-size: 28px;
    position: absolute;
    top: -13px;
    left: 47px;
  }
  .inner {
    h4 {
      &.title {
        font-size: 18px;
        font-weight: 700;
      }
    }
    p {
      font-size: 18px;
      margin-bottom: 0;
      color: rgba(29, 29, 36, 0.75);
    }
  }

  &:hover {
    .icon {
      background: $limeGreen;
      border-color: $limeGreen;
      color: $white;
    }
  }

  .blog-comment-form .inner .rnform-group input:focus,
  .blog-comment-form .inner .rnform-group textarea:focus {
    border-color: $lightGrayishBlue;
  }

  .success-message {
    color: green;
    font-size: 14px;
    margin-top: 20px;
  }
}
