.articles {
  background-color: $veryLightGray;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}
h2 {
  text-align: center;
}
.article {
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.875rem;

    @include breakpoint-up(medium) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint-up(large) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    border-radius: 0.3125rem;
    overflow: hidden;
    background-color: $white;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
    transition: all 150ms ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__image {
    height: 12.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__team {
    height: 12.5rem;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__text {
    padding: 1.875rem 1.875rem 2.5rem 1.875rem;
    color: $grayishBlue;

    @include breakpoint-up(medium) {
      padding: 1.875rem 1.5625rem;
    }
  }

  &__author {
    font-size: 0.625rem;
    margin-bottom: 0.75rem;
  }

  &__title {
    font-size: 1.0625rem;
    line-height: 1.2;
    color: $darkBlue;
    margin-bottom: 0.5rem;
  }

  &__description {
    font-size: 0.8125rem;
  }
}
