html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Public Sans", "Helvetica Neue";
  font-size: $font-md;
  font-weight: 300;
  color: $darkBlue;
  line-height: 1.3;

  @include breakpoint-up(large) {
    font-size: $font-md;
  }

  &.noscroll {
    overflow: hidden;
  }
}
h1,
h2,
h3 {
  margin-top: 0px;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

//Spacing
.container {
  max-width: 69.375rem;
  margin: 0 auto;

  &--pall {
    padding-top: 2.25rem;
    padding-right: 2.5rem;
    padding-bottom: 2.25rem;
    padding-left: 2.5rem;
  }
  &--py {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  &--px {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  &--pt {
    padding-top: 2.25rem;
  }
  &--pr {
    padding-right: 1.5rem;
  }
  &--pb {
    padding-right: 2.25rem;
  }
  &--pl {
    padding-right: 1.5rem;
  }
}

//Flexbox
.flex {
  display: flex;

  &-jc-sb {
    justify-content: space-between;
  }

  &-jc-c {
    justify-content: center;
  }

  &-ai-c {
    align-items: center;
  }
}

//Buttons
button,
.button {
  display: inline-block;
  padding: 0.875rem 2.1875rem;
  border-radius: 50px;
  background: linear-gradient(to right, $yellow, $yellow);
  border: 0;
  cursor: pointer;
  color: $white;
  font-weight: 400;
  font-size: $font-sm;
  color: $white;
  transition: opacity 300ms ease-in-out;

  &:hover {
    opacity: 0.75;
  }
}

// Visibility
.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none;
  }
}

.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none;
  }
}

iframe {
  border: none;
  height: 25rem;
  width: 100%;
}
