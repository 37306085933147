.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 200ms ease-in-out forwards;
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 200ms ease-in-out forwards;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Public Sans", "Helvetica Neue";
  font-size: 1.125rem;
  font-weight: 300;
  color: hsl(224deg, 26%, 21%);
  line-height: 1.3;
}
@media (min-width: 64em) {
  body {
    font-size: 1.125rem;
  }
}
body.noscroll {
  overflow: hidden;
}

h1,
h2,
h3 {
  margin-top: 0px;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

.container {
  max-width: 69.375rem;
  margin: 0 auto;
}
.container--pall {
  padding-top: 2.25rem;
  padding-right: 2.5rem;
  padding-bottom: 2.25rem;
  padding-left: 2.5rem;
}
.container--py {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}
.container--px {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.container--pt {
  padding-top: 2.25rem;
}
.container--pr {
  padding-right: 1.5rem;
}
.container--pb {
  padding-right: 2.25rem;
}
.container--pl {
  padding-right: 1.5rem;
}

.flex {
  display: flex;
}
.flex-jc-sb {
  justify-content: space-between;
}
.flex-jc-c {
  justify-content: center;
}
.flex-ai-c {
  align-items: center;
}

button,
.button {
  display: inline-block;
  padding: 0.875rem 2.1875rem;
  border-radius: 50px;
  background: linear-gradient(to right, hsl(45deg, 98%, 52%), hsl(45deg, 98%, 52%));
  border: 0;
  cursor: pointer;
  color: hsl(0deg, 0%, 100%);
  font-weight: 400;
  font-size: 0.875rem;
  color: hsl(0deg, 0%, 100%);
  transition: opacity 300ms ease-in-out;
}
button:hover,
.button:hover {
  opacity: 0.75;
}

@media (max-width: 63.9375em) {
  .hide-for-mobile {
    display: none;
  }
}

@media (min-width: 64em) {
  .hide-for-desktop {
    display: none;
  }
}

iframe {
  border: none;
  height: 25rem;
  width: 100%;
}

.topnav {
  overflow: hidden;
  background-color: hsl(0deg, 0%, 100%);
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}
@media screen and (min-width: 600px) {
  .topnav__links {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 1.25rem;
  }
}
.topnav__menu a {
  float: left;
  display: block;
  color: hsl(224deg, 26%, 21%);
  text-align: center;
  padding: 14px 31px;
  text-decoration: none;
  font-size: 17px;
}
.topnav__logo {
  margin-top: 0;
}

.topnav a:hover {
  background-color: hsl(220deg, 16%, 96%);
  color: hsl(224deg, 26%, 21%);
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
.about {
  background-color: hsl(220deg, 16%, 96%);
  padding-top: 6.25rem;
}
@media (min-width: 40em) {
  .about {
    text-align: left;
  }
}
.about__intro {
  margin-bottom: 3.75rem;
}
@media (min-width: 64em) {
  .about__intro {
    width: 90%;
  }
  .about__intro h2 {
    text-align: center;
    margin-left: 0;
  }
}
@media (min-width: 40em) {
  .about__grid {
    display: flex;
    flex-wrap: wrap;
  }
}
.about__item {
  padding: 0.9375rem;
}
@media (min-width: 40em) {
  .about__item {
    flex: 0 0 50%;
  }
}
@media (min-width: 64em) {
  .about__item {
    flex: 1;
  }
}
.about__image {
  margin-bottom: 1.875rem;
  width: 90%;
  text-align: center;
}
@media (min-width: 64em) {
  .about__image {
    margin-bottom: 2.75rem;
  }
}
.about__icon {
  margin-bottom: 1.875rem;
}
@media (min-width: 64em) {
  .about__icon {
    margin-bottom: 2.75rem;
  }
}
.about__title {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.15;
  color: hsl(224deg, 26%, 21%);
  margin-bottom: 1rem;
}
@media (min-width: 64em) {
  .about__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.about__description {
  font-size: 0.875rem;
  line-height: 1.5;
}

.articles {
  background-color: hsl(0deg, 0%, 98%);
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

h2 {
  text-align: center;
}

.article__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.875rem;
}
@media (min-width: 40em) {
  .article__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 64em) {
  .article__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.article__item {
  border-radius: 0.3125rem;
  overflow: hidden;
  background-color: hsl(0deg, 0%, 100%);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
  transition: all 150ms ease-in-out;
}
.article__item:hover {
  transform: scale(1.05);
}
.article__image {
  height: 12.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.article__team {
  height: 12.5rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.article__text {
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  color: hsl(233deg, 8%, 62%);
}
@media (min-width: 40em) {
  .article__text {
    padding: 1.875rem 1.5625rem;
  }
}
.article__author {
  font-size: 0.625rem;
  margin-bottom: 0.75rem;
}
.article__title {
  font-size: 1.0625rem;
  line-height: 1.2;
  color: hsl(224deg, 26%, 21%);
  margin-bottom: 0.5rem;
}
.article__description {
  font-size: 0.8125rem;
}

.contact {
  background-color: hsl(220deg, 16%, 96%);
  color: hsl(224deg, 26%, 21%);
  padding: 2.5rem;
  text-align: center;
  padding-top: 6.25rem;
}
@media (min-width: 64em) {
  .contact .container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "info1 info2" "info1 info2";
    gap: 1rem;
    justify-items: start;
    color: hsl(224deg, 26%, 21%);
  }
}
@media (min-width: 40em) {
  .contact {
    text-align: left;
  }
}
.contact__info {
  flex-direction: column;
  align-items: center;
  font-size: 0.9375rem;
}
@media (min-width: 64em) {
  .contact__info {
    justify-content: space-between;
    align-items: start;
  }
}
@media (min-width: 64em) {
  .contact__info.col1 {
    grid-area: info1;
  }
}
@media (max-width: 63.9375em) {
  .contact__info.col2 {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 64em) {
  .contact__info.col2 {
    grid-area: info2;
  }
}
.contact__info a {
  line-height: 2.25;
  transition: color 150ms ease-in-out;
}
.contact__info a:hover {
  color: hsl(136deg, 65%, 51%);
}
.contact__form label {
  display: block;
  margin-bottom: 0;
}
.contact__form input,
.contact__form textarea {
  display: block;
  width: 100%;
  padding: 0 20px;
  border: 2px solid hsl(220deg, 16%, 96%);
  border-radius: 5px;
  transition: all 0.3s ease;
  height: 50px;
  line-height: 46px;
  margin-bottom: 20px;
  outline: none;
  color: hsl(224deg, 26%, 21%);
  font-size: 15px;
  letter-spacing: 0.1px;
}
.contact__form input:focus,
.contact__form textarea:focus {
  border-color: hsl(233deg, 8%, 62%);
}
.contact__form textarea {
  height: 120px;
}
.contact__intro {
  margin-bottom: 3.75rem;
}
@media (min-width: 64em) {
  .contact__intro {
    width: 90%;
  }
  .contact__intro h2 {
    text-align: center;
    margin-left: 0;
    padding-left: 15.75rem;
  }
}
@media (min-width: 40em) {
  .contact__grid {
    display: flex;
    flex-wrap: wrap;
  }
}
.contact__item {
  padding: 0.9375rem;
}
@media (min-width: 40em) {
  .contact__item {
    flex: 0 0 50%;
  }
}
@media (min-width: 64em) {
  .contact__item {
    flex: 1;
  }
}
.contact__image {
  margin-bottom: 1.875rem;
  width: 90%;
  text-align: center;
}
@media (min-width: 64em) {
  .contact__image {
    margin-bottom: 2.75rem;
  }
}
.contact__icon {
  margin-bottom: 1.875rem;
}
@media (min-width: 64em) {
  .contact__icon {
    margin-bottom: 2.75rem;
  }
}
.contact__title {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.15;
  color: hsl(224deg, 26%, 21%);
  margin-bottom: 1rem;
}
@media (min-width: 64em) {
  .contact__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.contact__description {
  font-size: 0.875rem;
  line-height: 1.5;
}

/*==========================
  Contact
  Address
  ============================*/
.rn-address {
  margin-top: 30px;
  border: 1px solid #ebebeb;
  padding: 40px;
  border-radius: 10px;
  padding-top: 54px;
  background: #fff;
  padding-bottom: 50px;
}
.rn-address .icon {
  border: 1px solid #ebebeb;
  width: 78px;
  height: 78px;
  color: hsl(220deg, 16%, 96%);
  line-height: 73px;
  text-align: center;
  border-radius: 100%;
  background: hsl(0deg, 0%, 100%);
  font-size: 28px;
  position: absolute;
  top: -13px;
  left: 47px;
}
.rn-address .inner h4.title {
  font-size: 18px;
  font-weight: 700;
}
.rn-address .inner p {
  font-size: 18px;
  margin-bottom: 0;
  color: rgba(29, 29, 36, 0.75);
}
.rn-address:hover .icon {
  background: hsl(136deg, 65%, 51%);
  border-color: hsl(136deg, 65%, 51%);
  color: hsl(0deg, 0%, 100%);
}
.rn-address .blog-comment-form .inner .rnform-group input:focus,
.rn-address .blog-comment-form .inner .rnform-group textarea:focus {
  border-color: hsl(220deg, 16%, 96%);
}
.rn-address .success-message {
  color: green;
  font-size: 14px;
  margin-top: 20px;
}

.footer {
  background-color: hsl(224deg, 26%, 21%);
  color: hsl(0deg, 0%, 100%);
  padding: 2.5rem;
  text-align: center;
}
@media (min-width: 64em) {
  .footer .container {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 3fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "logo links1 links2 cta" "social links1 links2 copyright";
    gap: 1rem;
    justify-items: start;
  }
}
.footer a {
  color: hsl(0deg, 0%, 100%);
}
.footer__logo {
  display: inline-block;
}
@media (max-width: 63.9375em) {
  .footer__logo {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 64em) {
  .footer__logo {
    grid-area: logo;
  }
}
@media (max-width: 63.9375em) {
  .footer__social {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 64em) {
  .footer__social {
    grid-area: social;
    align-self: end;
  }
}
.footer__social a {
  display: inline-block;
  height: 1.25rem;
}
.footer__social a svg path {
  transition: fill 150ms ease-in-out;
}
.footer__social a:hover svg path {
  fill: hsl(136deg, 65%, 51%);
}
.footer__social a:not(:last-child) {
  margin-right: 1rem;
}
.footer__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9375rem;
}
@media (min-width: 64em) {
  .footer__links {
    justify-content: space-between;
    align-items: start;
  }
}
@media (min-width: 64em) {
  .footer__links.col1 {
    grid-area: links1;
  }
}
@media (max-width: 63.9375em) {
  .footer__links.col2 {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 64em) {
  .footer__links.col2 {
    grid-area: links2;
  }
}
.footer__links a {
  line-height: 2.25;
  transition: color 150ms ease-in-out;
}
.footer__links a:hover {
  color: hsl(136deg, 65%, 51%);
}
@media (min-width: 64em) {
  .footer__cta {
    grid-area: cta;
    text-align: right;
    justify-self: end;
  }
}
@media (max-width: 63.9375em) {
  .footer__cta a.button {
    margin-bottom: 1.875rem;
  }
}
.footer__copyright {
  font-size: 0.8125rem;
  color: hsl(233deg, 8%, 62%);
}
@media (min-width: 64em) {
  .footer__copyright {
    grid-area: copyright;
    align-self: end;
    justify-self: end;
  }
}
.footer .attribution {
  margin-top: 4rem;
  font-size: 0.875rem;
}

.hero {
  background-color: hsl(0deg, 0%, 98%);
  background-image: url("/public/assets/images/stars.jpeg");
  margin-top: 8.75rem;
}
@media (min-width: 64em) {
  .hero .container {
    display: flex;
    align-items: center;
  }
}
.hero__image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 64em) {
  .hero__image {
    background-image: none;
  }
}
@media (min-width: 64em) {
  .hero__image::before {
    content: "";
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    width: 120%;
    background-size: 100%;
    background-position: 0% 50%;
  }
}
.hero__image::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 40em) {
  .hero__image::after {
    background-size: 52%;
  }
}
@media (min-width: 64em) {
  .hero__image::after {
    width: 120%;
    height: 100%;
    background-size: 100%;
    background-position: center 50%;
    left: 0%;
  }
}
.hero__text {
  text-align: center;
}
@media (min-width: 64em) {
  .hero__text {
    flex: 2;
    order: 1;
    text-align: left;
  }
}
.hero__text h1 {
  font-weight: 300;
  font-size: 2.31rem;
  line-height: 1.15;
  color: hsl(0deg, 0%, 100%);
  margin-bottom: 1.5rem;
}
@media (min-width: 64em) {
  .hero__text h1 {
    font-size: 3.25rem;
  }
}
.hero__text p {
  line-height: 1.5;
  margin-bottom: 2.25rem;
  color: hsl(0deg, 0%, 100%);
}

.mission {
  background-color: hsl(0deg, 0%, 100%);
  padding-top: 6.25rem;
}
@media (min-width: 40em) {
  .mission {
    text-align: center;
  }
}
.mission__intro {
  margin-bottom: 3.75rem;
}
@media (min-width: 64em) {
  .mission__intro {
    width: 90%;
  }
  .mission__intro h2 {
    text-align: center;
    margin-left: 0;
  }
}
@media (min-width: 40em) {
  .mission__grid {
    display: flex;
    flex-wrap: wrap;
  }
}
.mission__item {
  padding: 0.9375rem;
}
@media (min-width: 40em) {
  .mission__item {
    flex: 0 0 50%;
  }
}
@media (min-width: 64em) {
  .mission__item {
    flex: 1;
  }
}
.mission__image {
  margin-bottom: 1.875rem;
  width: 90%;
  text-align: center;
}
@media (min-width: 64em) {
  .mission__image {
    margin-bottom: 2.75rem;
  }
}
.mission__icon {
  margin-bottom: 1.875rem;
}
@media (min-width: 64em) {
  .mission__icon {
    margin-bottom: 2.75rem;
  }
}
.mission__title {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.15;
  color: hsl(224deg, 26%, 21%);
  margin-bottom: 1rem;
}
@media (min-width: 64em) {
  .mission__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.mission__description {
  font-size: 0.875rem;
  line-height: 1.5;
}

.aoe {
  background-image: url("../../public/assets/images/stars.jpeg");
  color: hsl(0deg, 0%, 100%);
  padding-top: 6.25rem;
}
@media (min-width: 40em) {
  .aoe {
    text-align: center;
  }
}
.aoe__intro {
  margin-bottom: 3.75rem;
}
@media (min-width: 64em) {
  .aoe__intro h2 {
    text-align: center;
    margin-left: 0;
  }
}
@media (min-width: 40em) {
  .aoe__grid {
    display: flex;
    flex-wrap: wrap;
  }
}
.aoe__item {
  padding: 0.9375rem;
}
@media (min-width: 40em) {
  .aoe__item {
    flex: 0 0 50%;
  }
}
@media (min-width: 64em) {
  .aoe__item {
    flex: 1;
  }
}
.aoe__image {
  margin-bottom: 1.875rem;
  width: 90%;
  text-align: center;
}
@media (min-width: 64em) {
  .aoe__image {
    margin-bottom: 2.75rem;
  }
}
.aoe__icon {
  margin-bottom: 1.875rem;
}
@media (min-width: 64em) {
  .aoe__icon {
    margin-bottom: 2.75rem;
  }
}
.aoe__title {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.15;
  color: hsl(0deg, 0%, 100%);
  margin-bottom: 1rem;
}
@media (min-width: 64em) {
  .aoe__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.aoe__description {
  font-size: 0.875rem;
  line-height: 1.5;
}

.ourservices {
  background-color: hsl(0deg, 0%, 100%);
  color: hsl(224deg, 26%, 21%);
  padding-top: 6.25rem;
}
@media (min-width: 40em) {
  .ourservices {
    text-align: left;
  }
}
.ourservices__intro {
  margin-bottom: 3.75rem;
}
@media (min-width: 64em) {
  .ourservices__intro {
    width: 90%;
  }
  .ourservices__intro h2 {
    text-align: center;
    margin-left: 0;
  }
}
@media (min-width: 40em) {
  .ourservices__grid {
    display: flex;
    flex-wrap: wrap;
  }
}
.ourservices__item {
  padding: 0.9375rem;
}
@media (min-width: 40em) {
  .ourservices__item {
    flex: 0 0 50%;
  }
}
@media (min-width: 64em) {
  .ourservices__item {
    flex: 1 1 1;
  }
}
.ourservices__image {
  margin-bottom: 1.875rem;
  width: 90%;
  text-align: center;
}
@media (min-width: 64em) {
  .ourservices__image {
    margin-bottom: 2.75rem;
  }
}
.ourservices__icon {
  margin-bottom: 1.875rem;
}
@media (min-width: 64em) {
  .ourservices__icon {
    margin-bottom: 2.75rem;
  }
}
.ourservices__title {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.15;
  color: hsl(224deg, 26%, 21%);
  margin-bottom: 1rem;
}
@media (min-width: 64em) {
  .ourservices__title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.ourservices__description {
  font-size: 0.875rem;
  line-height: 1.5;
}/*# sourceMappingURL=style.css.map */