.hero {
  background-color: $veryLightGray;
  background-image: url("/public/assets/images/stars.jpeg");
  margin-top: 8.75rem;
  .container {
    @include breakpoint-up(large) {
      display: flex;
      align-items: center;
    }
  }

  &__image {
    position: relative;
    //background-image: url("../assets/images/bg-intro-mobile.svg");
    background-size: cover;
    //background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    //min-height: 27.5rem;
    @include breakpoint-up(large) {
      //flex: 3;
      //order: 2;
      //height: 30.2rem;
      background-image: none;
    }

    &::before {
      @include breakpoint-up(large) {
        content: "";
        position: absolute;
        height: 100%;
        //background-image: url("../assets/images/bg-intro-desktop.svg");
        background-repeat: no-repeat;
        background-position: center bottom;
        width: 120%;
        background-size: 100%;
        background-position: 0% 50%;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      //background-image: url("/public/assets/images/image-people.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;

      @include breakpoint-up(medium) {
        background-size: 52%;
      }

      @include breakpoint-up(large) {
        width: 120%;
        height: 100%;
        background-size: 100%;
        background-position: center 50%;
        left: 0%;
      }
    }
  }

  &__text {
    text-align: center;
    @include breakpoint-up(large) {
      flex: 2;
      order: 1;
      text-align: left;
    }
    h1 {
      font-weight: 300;
      font-size: 2.31rem;
      line-height: 1.15;
      color: $white;
      margin-bottom: 1.5rem;

      @include breakpoint-up(large) {
        font-size: $font-xlg;
      }
    }

    p {
      line-height: 1.5;
      margin-bottom: 2.25rem;
      color: $white;
    }
  }
}
