//Colors
$darkBlue: hsl(224, 26%, 21%);
$limeGreen: hsl(136, 65%, 51%);
$yellow: hsl(45, 98%, 52%);
$brightCyan: hsl(192, 70%, 51%);
$grayishBlue: hsl(233, 8%, 62%);
$lightGrayishBlue: hsl(220, 16%, 96%);
$veryLightGray: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

// Font Sizes
$font-sm: 0.875rem; // 14px
$font-md: 1.125rem; // 18px
$font-lg: 1.25rem;
$font-xlg: 3.25rem;
