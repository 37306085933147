.mission {
  background-color: $white;
  padding-top: 6.25rem;

  @include breakpoint-up(medium) {
    text-align: center;
  }

  &__intro {
    margin-bottom: 3.75rem;

    @include breakpoint-up(large) {
      width: 90%;

      h2 {
        text-align: center;
        margin-left: 0;
      }
    }
  }

  &__grid {
    @include breakpoint-up(medium) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: 0.9375rem;

    @include breakpoint-up(medium) {
      flex: 0 0 50%;
    }

    @include breakpoint-up(large) {
      flex: 1;
    }
  }
  &__image {
    margin-bottom: 1.875rem;
    width: 90%;
    text-align: center;
    @include breakpoint-up(large) {
      margin-bottom: 2.75rem;
    }
  }

  &__icon {
    margin-bottom: 1.875rem;

    @include breakpoint-up(large) {
      margin-bottom: 2.75rem;
    }
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.15;
    color: $darkBlue;
    margin-bottom: 1rem;

    @include breakpoint-up(large) {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__description {
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
